<template>
  <component :is="selectedComponent" />
</template>

<script>
import HomePage from './components/HomePage.vue'
import HomePageMobile from './components/HomePageMobile.vue'

export default {
  name: 'App',
  components: {
    HomePage,
    HomePageMobile
  },
  data() {
    return {
      screenWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
  },
  computed: {
    selectedComponent() {
      return this.screenWidth < 768 ? HomePageMobile : HomePage;
    }
  },
  watch: {
    // 监听窗口宽度变化
    screenWidth(newWidth) {
      this.screenWidth = newWidth;
    },
    // 在窗口宽度变化后重新选择要显示的组件
    selectedComponent() {
      // 根据新的组件来刷新视图
      this.$forceUpdate();
    }
  },
  created() {
    // 监听窗口宽度变化
    window.addEventListener('resize', this.onWindowResize);
  },
  methods: {
    // 窗口宽度变化时的事件处理函数
    onWindowResize() {
      this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
