import { createApp } from 'vue';
import App from './App.vue';
import { createMetaManager } from 'vue-meta'; 
import { defaultOptions } from './metaOptions';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

const app = createApp(App);
app.use(createMetaManager(defaultOptions));
app.use(ElementPlus)

app.mount('#app');
