export const defaultOptions = {
    title: '小纸条帝国',
    meta: [
        {
            name: 'keywords',
            content: '小纸条,小纸条帝国,Flutter,APP,LittleNote,问答',
        },
        {
            name: 'description',
            content: '小纸条帝国APP致力于打造小而美的开源问答社区，仅供学习交流使用。',
        },
    ],
};





