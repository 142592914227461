<template>
    <div class="material-overlay">
        <img src="../assets/bg_top_.png" class="top-left-image" alt="" />
        <div class="site-info">
            <img src="../assets/logo.png" class="site-icon" alt="Site Icon" />
            <span class="site-name">Little Note</span>
        </div>
        <img src="../assets/bg_bottom_.png" class="bottom-right-image" alt="" />
    </div>
</template>
  
<script>
export default {
    name: 'MaterialOverlay',
}
</script>
  
<style scoped>
.material-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.top-left-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    height: auto;
}

.bottom-right-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45%;
    height: auto;
}
.site-info {
  position: absolute;
  top: 25px; 
  left: 25px; 
  display: flex;
  align-items: center;
  color: white; 
  font-weight: bold; 
  font-size: 36px;
}

.site-icon {
  width: 50px; 
  height: 50px; 
  margin-right: 10px;
}

.site-name {
  
}
</style>
  