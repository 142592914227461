<template>
    <div class="home-page-mobile">
        <div class="image-container">
            <img src="../assets/main.png" alt="" class="responsive-image" />
        </div>
        <h3 class="title-h3">下载新版小纸条帝国</h3>
        <div class="button-container">
            <a :href="androidDownloadLink" download>
                <el-button type="warning" color="dab684" :dark="false" round>安卓下载</el-button>
            </a>
            <el-button type="warning" color="dab684" :dark="false" @click="open" round>IOS下载</el-button>
        </div>
    </div>
</template>
  
<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'HomePage',
    data() {
        return {
            androidDownloadLink: '',
        };
    },
    mounted() {
        this.fetchLatestVersion();
    },
    methods: {
        open() {
            ElMessage({
                message: '上架中，敬请期待',
                type: 'warning',
            });
        },
        async fetchLatestVersion() {
            try {
                const response = await axios.get('https://littlenote.monian.net.cn/latest_version');
                let latestVersion = response.data.trim();
                console.log(latestVersion);

                this.androidDownloadLink = `https://littlenote.monian.net.cn/download/${latestVersion}/LittleNote.apk`;
            } catch (error) {
                console.error('获取最新版本失败', error);
            }
        }
    }
}
</script>
  
  
<style scoped>
.title-h3 {
    color: #dab684;
}

.home-page-mobile {
    text-align: center;
    padding: 20px;
}

.image-container {
    margin: 0 auto;
    max-width: 100%;
}

.responsive-image {
    width: 70%;
    height: auto;
}

.button-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
}
</style>
  