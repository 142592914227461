<template>
  <MaterialOverlay />
  <NavigationLink />
  <div class="image-container">
    <img src="../assets/main.png" class="left-image" alt="" />
    <img src="../assets/mobile.png" class="right-image" alt="" />
  </div>
  <div class="info-container fade-in">
    <h1 class="info-title"><strong>小纸条帝国</strong></h1>
    <div><span class="info-sub-title">项目开发组</span></div>
    <p>
      小纸条帝国APP致力于打造小而美的开源问答社区。APP UI设计
      由MYY提供，APP 前端采用Flutter开发，适用于安卓和IOS以及
      桌面平台使用，后端基于Spring Boot框架进行开发，代码均在Github开源。
      以上所有内容仅供学习交流使用。
    </p>
    <el-row class="mb-2">
      <el-col :span="5">
        <a :href="androidDownloadLink" download>
        <el-button type="warning" color="dab684" :dark=false round>安卓下载</el-button>
      </a>
      </el-col>
      <el-col :span="5"> 
        <el-button type="warning" color="dab684" :dark=false @click="open" round>IOS下载</el-button> 
      </el-col>
     
    </el-row>

  </div>
  <footer class="footer">
      <p>&copy; 2023 小纸条帝国. All Rights Reserved.</p>
    </footer> 
</template>

<script>
import MaterialOverlay from './MaterialOverlay.vue';
import NavigationLink from './NavigationLink.vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';

export default {
  name: 'HomePage',
  components: { MaterialOverlay, NavigationLink },
  data() {
        return {
            androidDownloadLink: '',
        };
    },
    mounted() {
        this.fetchLatestVersion();
    },
    methods: {
        open() {
            ElMessage({
                message: '上架中，敬请期待',
                type: 'warning',
            });
        },
        async fetchLatestVersion() {
            try {
                const response = await axios.get('https://littlenote.monian.net.cn/latest_version');
                let latestVersion = response.data.trim();
                console.log(latestVersion);

                this.androidDownloadLink = `https://littlenote.monian.net.cn/download/${latestVersion}/LittleNote.apk`;
            } catch (error) {
                console.error('获取最新版本失败', error);
            }
        }
    }
}

</script>

<style scoped>
.info-container {
  position: absolute;
  height: 400px;
  width: 500px;
  top: 35%;
  left: 100px;
}

.info-container p {
  color: grey;
}

.info-title {
  font-size: 48px;
  font-weight: 300;
  color: #dab684;
}

.info-sub-title {
  font-size: 32px;
  background-color: #eddbc0;
  color: white;
  letter-spacing: 5px;
}

.image-container {
  position: absolute;
  top: 10%;
  left: 35%;
  width: 50%;
  height: 80%;
  display: flex;
  justify-content: flex-end;
}

.left-image {
  width: 50%;
  height: auto;
  opacity: 0;
  position: absolute;
  z-index: 0;
}

.right-image {
  width: 35%;
  height: auto;
  opacity: 0;
  top: 10%;
  position: absolute;
  z-index: 1;
}

.left-image {
  left: 0;
  animation: slideLeft 1s ease-in-out 0.5s forwards, fadeIn 1s ease-in-out 0.5s forwards;
}

.right-image {
  right: 0;
  animation: slideRight 1s ease-in-out 0.5s forwards, fadeIn 1s ease-in-out 0.5s forwards;
}

@keyframes slideLeft {
  0% {
    left: 0;
  }

  100% {
    left: 15%;
  }
}

@keyframes slideRight {
  0% {
    right: 0;
  }

  100% {
    right: 10%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.footer {
  color: #333;
  text-align: center;
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3%;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
