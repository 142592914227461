<template>
    <div class="nav">
        <a href="#" class="nav-link">主页</a>
        <a href="#" class="nav-link">关于</a>
        <a href="#" class="nav-link">项目详情</a>
        <a href="#" class="nav-link">联系我们</a>
    </div>
</template>
  
<script>
export default {
    name: 'NavigationLink',
}
</script>

<style scoped>
.nav {
    position: absolute;
    top: 35px;
    right: 100px;
    display: flex;
    gap: 100px;
    font-weight: bold;
}

.nav-link {
    text-decoration: none;
    color: #dab684;
    position: relative;
    /* 添加相对定位 */
}

.nav-link::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #dab684;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
</style>
  